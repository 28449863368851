import React from "react";
import AboutUs from "./AboutUs";
import OurTeam from "./OurTeam";

const AboutAndTeam = () =>{
    return(
        <>
        <OurTeam/>
        </>
    );
};

export default AboutAndTeam;